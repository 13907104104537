import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Admin, AdminSecond, AdminThird, Layout, Eror404 } from "./pages"
import { providerConnectParams } from './pages/transactionHandler';
import { universalProvider } from './pages/transactionHandler';
import Login from './Login';
import useToken from './useToken';

const getToken = () => {
  const tokenString: any = sessionStorage.getItem('token');
  return tokenString
};

// const [token, setToken] = useState(getToken());

export const setSessionToken = (userToken: any) => {
  sessionStorage.setItem('token', JSON.stringify(userToken));
  // setToken(userToken.token);
};

function App() {
  const [token, setToken] = useState(getToken());
  console.log(token)

  if (!token) {
    return (
      <Login setToken={setToken} />
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Admin setToken={setToken} setSessionToken={setSessionToken} token={token} />} />
        <Route path='wallet/:wallet' element={<AdminSecond />} />
        <Route path='wallet/session/:wallet' element={<AdminThird />} />
        <Route path='*' element={<Eror404 />} />
      </Routes>
    </BrowserRouter>
  );
}

const ConnectWallet = ({universalProvider}: {universalProvider: any}) => {
  const [someVal, setSomeVal] = useState<number>(1)

  const compareAddress = (val: string) => {
    var dt = ''
    dt += val[0] + val[1] + val[2] + val[3] + val[4] + val[5] + '...' + val[val.length-4] + val[val.length-3] + val[val.length-2] + val[val.length-1]
    return dt
  }

  const update = () => {
    setSomeVal(someVal+1)
  }
  // console.log(universalProvider)
  // console.log(universalProvider.session)
  return (
    <div className="connect-button-wrapper" id='connect-wallet-div' onClick={update}>
      {universalProvider.session === undefined?<button className="connect-button" onClick={ async () => await universalProvider.connect(providerConnectParams)}>{"Подключить"}</button> : <button className="connect-button" onClick={ async () => await universalProvider.disconnect()}>{"Отключиться"}</button>}
    </div>
  )
}

export const ConnectWalletDiv = <ConnectWallet universalProvider={universalProvider} />

export default App;
